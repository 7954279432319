<template>
  <a-form-model
    :class="$style.formModel"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <div :class="$style.formBox">
      <a-form-model-item label="广播类型" prop="type">
        <a-tooltip :class="$style.label_tooltip">
          <template slot="title">
            文本：设备端用TTS发音朗读输入的文本内容
            <br />
            语音：播放录制的音频内容（限Plus设备）
          </template>
          <XIcon type="question-circle" />
        </a-tooltip>
        <a-radio-group
          default-value="TEXT"
          v-model="form.type"
          @change="handleTypeChange"
        >
          <a-radio value="TEXT">
            文本
          </a-radio>
          <a-radio value="VOICE">
            语音 <span :class="$style.beta_tip"> beta</span>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('hat.broadcast.content')"
        prop="content"
        v-if="form.type === 'TEXT'"
      >
        <a-input
          :placeholder="$t('hat.broadcast.form.inputContent')"
          v-model="form.content"
          :maxLength="140"
          @blur="onBlur($event, 'content')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('hat.broadcast.content')"
        v-else-if="form.type === 'VOICE'"
      >
        <Record
          ref="record"
          :operation-type="operationType"
          :content="form.content"
        ></Record>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('hat.deviceManagement.form.selectDevice')"
        prop="saveType"
      >
        <a-radio-group default-value="DEVICE" v-model="form.saveType">
          <a-radio value="DEVICE">
            {{ $t('hat.electronFence.byDevice') }}
          </a-radio>
          <a-radio value="GROUP">
            {{ $t('hat.electronFence.byGroup') }}
          </a-radio>
          <a-radio value="PROJECT">
            {{ $t('hat.deviceManagement.form.project') }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div :class="$style.radioItem">
        <a-form-model-item prop="device" v-if="form.saveType === 'DEVICE'">
          <a-select
            mode="multiple"
            v-model="devices"
            show-search
            option-filter-prop="children"
            :filter-option="true"
            :class="$style.selectList"
            @change="handleChange"
          >
            <a-select-option v-for="item in deviceList" :key="item.id">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="group" v-else-if="form.saveType === 'GROUP'">
          <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            :filter-option="true"
            v-model="groups"
            :class="$style.selectList"
            @change="handleChange"
          >
            <a-select-option v-for="item in groupList" :key="item.id">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          :label="$t('hat.deviceManagement.form.belongingProject')"
          prop="project"
          v-else
        >
          <project-select
            v-model="form.projectId"
            style="margin-bottom: 8px;"
          />
        </a-form-model-item>
        <!-- <span :class="$style.tipText">共包含1台设备</span> -->
      </div>
    </div>
    <div :class="$style.formBox">
      <a-form-model-item
        :label="$t('hat.broadcast.form.broadcastTime')"
        prop="broadcastTime"
      >
        <a-date-picker
          style="width: 250px;"
          format="YYYY-MM-DD HH:mm:ss"
          v-model="form.broadcastTime"
          :show-time="{ defaultValue: $moment('00:00:00', 'HH:mm:ss') }"
        />

        <a-checkbox v-model="form.autoPlay">
          <span :class="$style.tipText">
            自动播放（到达播放时间后设备自动播放）
            <span :class="$style.beta_tip"> beta</span>
          </span>
        </a-checkbox>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('hat.broadcast.form.listeningReceipt')"
        prop="receipts"
      >
        <a-switch
          default-checked
          v-model="form.receiptStatus"
          style="margin-right: 10px;"
        />
        <span :class="$style.tipText">{{
          $t('hat.broadcast.form.listeningReceiptTip')
        }}</span>
      </a-form-model-item>
      <a-form-model-item label="收听回复" prop="receipts">
        <span :class="[$style.beta_tip, $style.label_tip]"> beta</span>
        <a-switch
          default-checked
          v-model="form.replyStatus"
          style="margin-right: 10px;"
        />
        <span :class="$style.tipText">
          开启后可要求Plus设备录音回复
        </span>
      </a-form-model-item>
    </div>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { addBroadcast, putBroadcast } from '@/services/smart-hat/broadcast';
import {
  getDeviceList,
  getGroupList,
} from '@/services/smart-hat/device-management';
import { createFormModal } from '@triascloud/x-components';
import { crossStorageModule } from '@/enum/store.js';
import { replaceContent } from '@/views/hat/utils.js';
import Record from '@/views/hat/broadcast/components/record.vue';
import ProjectSelect from '@/views/hat/components/project-select/project-select.vue';

@Component({
  components: {
    ProjectSelect,
    Record,
  },
})
export default class DeviceForm extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) operationType;
  @Prop({ type: String, default: '' }) submitType;

  mounted() {
    this.initData();
    this.getSelectList();
  }
  async initData() {
    if (this.operationType === 'edit') {
      const data = this.editData;
      this.initFormData(data);
    }
    if (this.operationType === 'copy') {
      const data = JSON.parse(JSON.stringify(this.editData));
      // data.theme = `${data.theme}-${this.$moment().format(
      //   'YYYY-MM-DD HH:mm:ss',
      // )}`;
      this.initFormData(data);
    }
  }
  form = {
    autoPlay: false,
    type: 'TEXT',
    broadcastStatus: '',
    broadcastTime: this.$moment(new Date())
      .add(1, 'minute')
      .format('YYYY-MM-DD HH:mm:ss'),

    content: '',
    idxSaveId: [],
    receiptStatus: false,
    replyStatus: false,
    projectId: undefined,
    saveType: 'DEVICE',
  };
  rules = {
    content: [
      {
        required: true,
        message: this.$t('hat.broadcast.form.requiredContent'),
      },
    ],

    saveType: [
      {
        required: true,
        message: this.$t('hat.deviceManagement.form.requiredDevice'),
      },
    ],
    broadcastTime: [
      {
        required: true,
        message: this.$t('hat.broadcast.form.requiredBroadcast'),
      },
    ],
  };
  devices = [];
  groups = [];
  deviceList = [];
  groupList = [];
  @crossStorageModule.State('tenant') tenant;
  async getSelectList() {
    try {
      await Promise.all([this.getDevices(), this.getGroups()]);
    } finally {
      if (this.operationType !== 'add') {
        switch (this.editData.saveType) {
          case 'PROJECT':
            this.form.projectId = this.editData.idxSaveId[0] || undefined;
            break;
          case 'DEVICE':
            this.devices = this.editData.idxSaveId;
            break;
          case 'GROUP':
            this.groups = this.editData.idxSaveId;
            break;
        }
      }
    }
  }
  async getDevices() {
    const data = {
      current: 1,
      size: 500,
      type: this.form.type === 'VOICE' ? 'PLUS' : '',
    };
    this.deviceList = (await getDeviceList(data)).records.map(item => ({
      id: item.deviceId,
      value: item.userId
        ? `${item.deviceName}(${item.userName})`
        : item.deviceName,
    }));
  }
  async getGroups() {
    const data = {
      current: 1,
      size: 500,
    };
    this.groupList = (await getGroupList(data)).records.map(item => ({
      id: item.pkId,
      value: item.deviceGroupName,
    }));
  }
  handleTypeChange() {
    this.form.saveType = 'DEVICE';
    this.form.projectId = undefined;
    this.devices = [];
    this.groups = [];
    this.getDevices();
    this.form.content = '';
  }
  handleChange(v) {
    this.form.idxSaveId = v;
  }
  initFormData(data) {
    this.form.broadcastStatus = data.broadcastStatus;
    this.form.type = data.type;
    this.form.autoPlay = data.autoPlay;
    this.form.replyStatus = data.replyStatus;
    this.getTimeLag(data.broadcastTime) > 0
      ? (this.form.broadcastTime = this.$moment(data.broadcastTime).format(
          'YYYY-MM-DD HH:mm:ss',
        ))
      : (this.form.broadcastTime = this.$moment(new Date())
          .add(1, 'minute')
          .format('YYYY-MM-DD HH:mm:ss'));

    this.form.content = data.content;
    this.form.receiptStatus = data.receiptStatus;
    this.form.saveType = data.saveType;
    this.form.idxSaveId = data.idxSaveId;
  }
  getTimeLag(time) {
    return this.$moment(time).valueOf() - this.$moment(new Date()).valueOf();
  }

  async handleConfirm() {
    const playTime = this.getTimeLag(this.form.broadcastTime) / 1000;
    const text = this.$t('hat.broadcast.form.sendBroadcast');
    const tips = this.$t('hat.broadcast.form.broadcastTip', {
      value: this.timeConversion(playTime),
    });
    await createFormModal(
      () => (
        <div class={this.$style.formTipText}>
          <p>{text}</p>
          <p slot="subTxt">{tips}</p>
        </div>
      ),
      {
        width: '442px',
        title: this.$t('hat.deviceManagement.operateConfirm'),
        onOk: async () => {
          try {
            const type = this.operationType === 'edit';
            if (type) {
              this.form.pkId = this.editData.pkId;
            }
            const str = type
              ? this.$t('hat.deviceManagement.editModal.modified')
              : this.$t('hat.deviceManagement.editModal.addSuccess');
            type
              ? await putBroadcast(this.form)
              : await addBroadcast(this.form);
            this.$message.success(str);
          } catch {
            return false;
          }
        },
      },
    );
  }
  async handleSave() {
    const type = this.operationType === 'edit';
    if (type) {
      this.form.pkId = this.editData.pkId;
    }
    const str = type
      ? this.$t('hat.deviceManagement.editModal.modified')
      : this.$t('hat.deviceManagement.editModal.addSuccess');
    type ? await putBroadcast(this.form) : await addBroadcast(this.form);
    this.$message.success(str);
  }
  onBlur(e, key) {
    const { value } = e.target;
    this.form[key] = replaceContent(value);
  }
  timeConversion(value) {
    if (value < 60) {
      return `1${this.$t('unit.m')}`;
    }
    let time = '';
    let day = parseInt(value / 3600 / 24);
    let hour = parseInt((value / 3600) % 24);
    let minutes = parseInt((value % 3600) / 60);
    if (day > 0) {
      time = time + day + this.$t('screen.days');
    }
    if (hour > 0) {
      time = time + hour + this.$t('hat.screen.hours');
    }
    if (minutes > 0) {
      time = time + minutes + this.$t('unit.m');
    }
    return time;
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (this.form.type === 'VOICE' && !this.$refs.record.duration) {
          this.$message.error('录音内容不能为空');
        }
        if (this.form.saveType === 'DEVICE' && this.devices.length < 1) {
          this.$message.error(
            this.$t('hat.deviceManagement.form.requiredDevice'),
          );
          valid = false;
        }
        if (this.form.saveType === 'GROUP' && this.groups.length < 1) {
          this.$message.error(
            this.$t('hat.deviceManagement.form.requiredGroupId'),
          );
          valid = false;
        }
        if (this.form.saveType === 'PROJECT' && !this.form.projectId) {
          this.$message.error(
            this.$t('hat.deviceManagement.editModal.validate.messageOne'),
          );
          valid = false;
        }
        if (valid) {
          if (this.getTimeLag(this.form.broadcastTime) < 0) {
            // this.$message.error('播报时间不得晚于当前时间！');
            // return;
            this.form.broadcastTime = this.$moment(new Date())
              .add(10, 'second')
              .format('YYYY-MM-DD HH:mm:ss');
          }
          this.submitType === 'confirm'
            ? (this.form.broadcastStatus = 'COMPLETED')
            : (this.form.broadcastStatus = 'DRAFT');
          this.form.broadcastTime = this.$moment(
            this.form.broadcastTime,
          ).format('YYYY-MM-DD HH:mm:ss');
          if (this.form.saveType === 'PROJECT') {
            this.form.idxSaveId = [this.form.projectId];
          }

          if (this.form.type === 'VOICE') {
            this.form.content = await this.$refs.record.handleUpload();
          }

          delete this.form.projectId;
          try {
            this.submitType === 'confirm'
              ? await this.handleConfirm()
              : await this.handleSave();
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module>
.formModel {
  padding-bottom: 122rpx;
  .formBox {
    width: 49%;
    .radioItem {
    }
    .selectList {
      :global {
        .ant-select-selection--multiple {
          max-height: 140px;
          overflow-y: auto;
        }
      }
    }
  }
}
.tipText {
  color: #999999;
}
.formTipText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
}
.selectGroups {
  .row {
    margin-bottom: 10px;
    .add {
      display: flex;
      justify-content: space-between;
      span {
        cursor: pointer;
        color: var(--primary);
      }
    }
  }
}
.beta_tip {
  width: 35px;
  height: 20px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  background: var(--danger);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.label_tip {
  position: absolute;
  top: -35px;
  left: 68px;
}
.label_tooltip {
  cursor: pointer;
  position: absolute;
  top: -32px;
  left: 63px;
}
</style>
