<template>
  <div>
    <div v-if="!isEdit">
      <div v-if="isRecording" :class="$style.record_warp">
        <div>
          <div
            :class="[$style.action_btn, $style.start_btn]"
            @click="handleStart"
            v-if="amrIsRecording === false"
          >
            <span> 开始录音</span>
          </div>

          <div :class="$style.action_btn" @click="handleStop" v-else>
            <span> {{ secondsToMinutes(second) }}</span>
          </div>
        </div>
        <div>点击开始录音，限1分钟时长</div>
      </div>
      <div v-else :class="$style.record_warp">
        <div
          :class="[$style.action_btn, $style.play_btn]"
          @click="handlePlay()"
        >
          <span> {{ !isplaying ? '播放录音' : '暂停播放' }}</span>
        </div>
        <div>
          {{ secondsToMinutes(this.duration.toFixed(0)) }}

          <x-icon
            :class="$style.icon"
            type="tc-icon-delete"
            @click="handleDelAudio()"
          />
        </div>
      </div>
    </div>
    <div v-else :class="$style.record_warp">
      <div :class="[$style.action_btn, $style.play_btn]" @click="handlePlay()">
        <span> {{ !isplaying ? '播放录音' : '暂停播放' }}</span>
      </div>
      <div>
        {{ secondsToMinutes(this.duration.toFixed(0)) }}

        <x-icon
          :class="$style.icon"
          type="tc-icon-delete"
          @click="handleDelAudio()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';

const lamejs = require('lamejs');
var BenzAMRRecorder = require('benz-amr-recorder');

@Component()
export default class Record extends Vue {
  @Prop({ type: String, default: '' }) content;
  @Prop({ type: String, default: '' }) operationType;
  recorder = null;
  isplaying = false;
  isRecording = true;
  isEdit = false;
  audioSrc = '';
  amrRec = null;
  amrIsRecording = false;
  second = 0;
  timer = null;
  async created() {
    if (this.operationType === 'edit' && this.content) {
      this.isEdit = true;
      this.audioSrc = await this.loadOssPath(this.content);
      this.initRecord();
      this.amrRec.initWithUrl(this.audioSrc).then(() => {});
    }
  }
  initRecord() {
    this.amrRec = new BenzAMRRecorder();
    this.amrRec.onStartRecord(() => {
      console.log('开始录音');
      this.timer = setInterval(() => {
        if (this.second === 59) {
          clearInterval(this.timer);

          this.handleStop();
          this.second = 0;
          return;
        }
        this.second++;
      }, 1000);

      this.amrIsRecording = this.amrRec.isRecording();
    });
    this.amrRec.onFinishRecord(() => {
      console.log('结束录音');
      this.amrIsRecording = this.amrRec.isRecording();
      this.isRecording = false;
      clearInterval(this.timer);
      this.second = 0;
      // this.amrRec.play();
    });

    this.amrRec.onEnded(() => {
      this.isplaying = this.amrRec.isPlaying();
    });
  }

  get duration() {
    try {
      return this.amrRec ? this.amrRec.getDuration() : 0;
    } catch (e) {
      return 0;
    }
  }
  handleDelAudio() {
    console.log('handleDelAudio');
    this.isplaying = false;
    this.isEdit = false;
    this.isRecording = true;
    // this.amrRec.pause();
    this.amrRec.destroy();
  }
  async loadOssPath(filePath) {
    // 判断是否是完整的文件路径
    const customService = new UploadService('/oss/iot/oss');
    return await customService.getAuth(filePath);
  }
  async uploadFile(file) {
    const uploadPath = `/blob/mp3`;
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    return await customService.upload(file, uploadPath, () => {}, false);
  }

  convertToMp3(wavDataView) {
    // 获取wav头信息
    const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
    const { channels, sampleRate } = wav;
    const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 8);
    // 获取左右通道数据
    const result = this.recorder.getChannelData();
    const buffer = [];

    const leftData =
      result.left &&
      new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
    const rightData =
      result.right &&
      new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
    const remaining = leftData.length + (rightData ? rightData.length : 0);

    const maxSamples = 1152;
    for (let i = 0; i < remaining; i += maxSamples) {
      const left = leftData.subarray(i, i + maxSamples);
      let right = null;
      let mp3buf = null;

      if (channels === 2) {
        right = rightData.subarray(i, i + maxSamples);
        mp3buf = mp3enc.encodeBuffer(left, right);
      } else {
        mp3buf = mp3enc.encodeBuffer(left);
      }

      if (mp3buf.length > 0) {
        buffer.push(mp3buf);
      }
    }
    const enc = mp3enc.flush();
    if (enc.length > 0) {
      buffer.push(enc);
    }

    const blob = new Blob(buffer, { type: 'audio/mp3' });
    return new File([blob], 'record.mp3', {
      type: blob.type,
    });
  }
  async handleUpload() {
    const file = new File([this.amrRec.getBlob()], 'record.amr', {
      type: 'audio/amr',
    });
    return await this.uploadFile(file);
  }

  async handleDownload() {
    // const mp3Blob = this.convertToMp3(this.amr.getBlob());
    // this.recorder.download(mp3Blob, 'recorder', 'mp3');
    // window.location.href = window.URL.createObjectURL(this.amrRec.getBlob());
    const file = new File([this.amrRec.getBlob()], 'record.amr', {
      type: 'audio/amr',
    });
    const res = await this.uploadFile(file);
    console.log('res', res);
  }

  handleStart() {
    this.initRecord();
    this.amrRec.initWithRecord().then(() => {
      this.amrRec.startRecord();
    });
  }

  handleStop() {
    this.amrRec.isRecording() && this.amrRec.finishRecord();

    //
    // this.recorder.stop();
    // this.isRecording = false;
  }
  playTime = 0;
  handlePlay() {
    this.isplaying = !this.amrRec.isPlaying();
    this.amrRec.playOrPauseOrResume();
  }

  secondsToMinutes(seconds) {
    let minutes = Math.floor(seconds / 60); // 取整分钟数
    let remainingSeconds = seconds % 60; // 余下秒数
    minutes = minutes >= 10 ? minutes : `0${minutes}`;
    remainingSeconds =
      remainingSeconds >= 10 ? remainingSeconds : `0${remainingSeconds}`;
    return `${minutes}:${remainingSeconds}`;
  }
}
</script>
<style lang="less" module>
.start_btn {
  font-size: 16px !important;
}
.play_btn {
  font-size: 16px !important;
  background: var(--warning) !important;
}
.action_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: var(--danger);
}
.icon {
  cursor: pointer;
}
.record_warp {
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.audio_warp {
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;

  audio {
    margin-right: 10px;
  }
}
</style>
